import { defineStore } from 'pinia';

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    requestsProgress: false,
    showPreloader: false,
  }),
  getters: {
    isShowPreloader: (state) => {
      return state.showPreloader;
    },
  },
  actions: {
    changeRequestProgress(value) {
      this.requestsProgress = value;
    },

    changeShowPreloader(value) {
      this.showPreloader = value;
    },
  },
});
